@import "./mixins/breakpoint.scss";
$max: 100;
$offset: 1;
$unit: 'px'; // Feel free to change the unit.
@mixin list-loop($className, $styleName) {
    $i: 0;
    @while $i <=$max {
        #{$className + $i} {
            #{$styleName}: #{$i + $unit};
        }
        $i: $i+$offset;
    }
}

@include list-loop('.m-', 'margin');
@include list-loop('.mt-', 'margin-top');
@include list-loop('.mb-', 'margin-bottom');
@include list-loop('.ml-', 'margin-left');
@include list-loop('.mr-', 'margin-right');
@include list-loop('.p-', 'padding');
@include list-loop('.pt-', 'padding-top');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.pl-', 'padding-left');
@include list-loop('.pr-', 'padding-right');
@include list-loop('.ft-', 'font-size');
// xs screen
// @include breakpoint("xs") {
//     $max: 100;
//     $offset: 1;
//     $unit: 'px';
//     @mixin list-loop-xs($className, $styleName) {
//         $i: 0;
//         @while $i <=$max {
//             #{$className + $i} {
//                 #{$styleName}: #{$i + $unit};
//             }
//             $i: $i+$offset;
//         }
//     }
//     @include list-loop-xs('.ft-xs', 'font-size');
// }
.ellipse {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
@import './mixins/shape.scss',
'../scss/variables.scss';

/* Cursor */

.cp {
    cursor: pointer !important;
}

.cna {
    cursor: not-allowed !important;
}


/* Text align */

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-justify {
    text-align: justify !important;
}


/* Text transformation */

.text-uppercase {
    text-transform: uppercase !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}


/** Line Height **/

.lh-05 {
    line-height: 0.5em;
}

.lh-1 {
    line-height: 1em;
}

.lh-15 {
    line-height: 1.5em;
}

.lh-2 {
    line-height: 2em;
}

.lh-25 {
    line-height: 2.5em;
}

.lh-3 {
    line-height: 3em;
}


/** Border Radius **/

.br-50 {
    border-radius: 50%;
}


/** Fonts **/

.ft-bp {
    font-family: "Battery-Park";
}

.ft-os {
    font-family: "Orator-Standard";
}

.ft-mt {
    font-family: "Montserrat";
}

// width
.w100 {
    width: 100px;
}

.w90 {
    width: 90px;
}

.w70 {
    width: 70px;
}

.min-w100 {
    min-width: 100px;
}

.wp-100 {
    width: 100%
}

//height
.h100p {
    height: 100%;
}

// Global Color
.bg-white {
    background-color: $app-color-white;
}

// Global utils
.no-shadow {
    box-shadow: none !important;
}
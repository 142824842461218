@import "./variables.scss";
.custom-scroll {
  scrollbar-color: $app-color-friargray $app-color-hitgray;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $app-color-hitgray;
  }
  &::-webkit-scrollbar-thumb {
    width: 15px;
    background: $app-color-friargray;
  }
  &-mini {
    scrollbar-color: $app-color-friargray $app-color-hitgray;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: $app-color-friargray;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $app-color-friargray;
      outline: 1.5px solid $app-color-friargray;
    }
    &::-moz-scroller {
      width: 10px;
    }
    &::-moz-scroller-track {
      background-color: $app-color-friargray;
    }
    &::-moz-scroller-thumb {
      background-color: $app-color-friargray;
      outline: 1.5px solid $app-color-friargray;
    }
  }
  &-thin {
    scrollbar-color: $app-color-friargray $app-color-hitgray;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: $app-inactive-color;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $app-color-friargray;
      outline: 1.5px solid $app-color-friargray;
    }
  }
}

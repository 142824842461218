.input {
    background: $app-color-blue-3;
    border: none;
    border-radius: 4px;
    height: 36px;
    & input {
        background: $app-color-blue-3;
        outline: none;
        border: none;
        padding: 13px 24px;
        width: calc(100% - 48px);
        border-radius: 4px;
        font-size: 16px;
        // font-family: 'Poppins';
        font-family: 'Nunito Sans', sans-serif;
        &::placeholder {
            // font-family: 'Poppins';
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 400;
            line-height: 24px;
            color: $app-color-gray-4;
        }
    }
}

.input-checkbox {
    position: absolute;
    opacity: 0;
    margin: 0;
    width: 20px;
    height: 20px;
    &+label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }
    &+label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 18px;
        height: 18px;
        background: $app-color-white;
        border: 1px solid $app-color-blue;
        border-radius: 2px;
    }
    &:hover+label:before {
        background: $app-color-white;
        border: 1px solid $app-color-blue;
        border-radius: 2px;
    }
    // Box focus
    // &:focus+label:before {
    //     box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    // }
    // Box checked
    &:checked+label:before {
        background: $app-color-blue;
        border: 1px solid $app-color-blue;
    }
    // Disabled state label.
    &:disabled+label {
        color: $app-color-white;
        cursor: auto;
    }
    // Disabled box.
    &:disabled+label:before {
        box-shadow: none;
        background: $app-color-white;
    }
    // Checkmark. Could be replaced with an image
    &:checked+label:after {
        content: '';
        position: absolute;
        left: 5px;
        top: 9px;
        background: $app-color-white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $app-color-white, 4px 0 0 $app-color-white, 4px -2px 0 $app-color-white, 4px -4px 0 $app-color-white, 4px -6px 0 $app-color-white, 4px -8px 0 $app-color-white;
        transform: rotate(45deg);
    }
}
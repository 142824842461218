@import "./variables.scss";
@import "./scrollbar.scss";
@import "./common.scss";
@import "./mixins/input.scss";
@import "./mixins/button.scss";
@import "./mixins/breakpoint.scss";

:root {
  --dh-grey: #{$app-color-friargray};
}

* {
  -webkit-tap-highlight-color: transparent;
}

$colors: (
  timesheet-app-theme: $app-color-red-text,
  );

@mixin color-modifiers {

  @each $name,
  $hex in $colors {
    --data-color-#{$name}: #{$hex};
  }
}

:root {
  @include color-modifiers;
}

body {
  margin: 0;
  // font-family: 'Poppins';
  font-family: "Nunito Sans",
    sans-serif;
  @extend .custom-scroll;
}

$app-color-white: #ffffff;
$app-color-red: #bb2328;
$app-color-red-text: #e24e53;
$app-color-blue: #4c6fff;
$app-color-blue-3: #edf2f7;
$app-color-hitgray: #a2b2b9;
$app-color-friargray: #82807a;
$app-inactive-color: #9f9f9f;
$app-color-gray-1: #f3f3f9;
$app-color-gray-2: #333333;
$app-color-gray-3: #646464;
$app-color-gray-4: #b6bfc9;
$app-color-gray-29: #5c5c5c;
$app-color-orange: #ee5339;
$app-color-green: #019612;
$app-light-blue: #f2f5ff;
$app-color-black: #000000;
$app-color-purple: #EAD8FD;
$app-color-green-1: #B9FFCE;
$app-color-blue-1: #BEECFB;
$app-color-red-1: #FCB9B8;
$app-color-yellow: #FFEAA5;
$app-color-black-1: #081A2B;
$app-color-white-1: #DCDCDC;
$app-color-white-2: #AFAFAF;
@import "./unit-conversion.scss";
@import "../variables.scss";
@import "../utils.scss";
@import "/src/assets/scss/mixins/design-util.scss";
// ------------------------------
//      Placeholder extends
// ------------------------------
%no-bg {
    background: transparent !important;
}

.ah-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    background-color: $app-color-white;
    color: $app-color-white;
    &:hover {
        background-color: $app-color-blue;
    }
    &.solid {
        background-color: $app-color-blue;
        border: 1px solid $app-color-blue;
        &:hover {
            background-color: $app-color-white;
            border: 1px solid $app-color-blue;
            color: $app-color-blue;
        }
    }
}

.timesheet-btn {
    font-family: "Montserrat", sans-serif;
    min-width: 100px;
    max-width: 154px;
    width: auto;
    max-height: 36px;
    font-size: 0.7em;
    outline: none !important;
    border: 0;
    @extend .cp;
    &:disabled,
    &.disabled {
        @extend .cna;
        opacity: 0.5;
    }
    &.solid {
        color: $app-color-gray-29;
        border: 1px solid $app-color-blue;
        border-radius: 8px;
    }
    &.outline,
    &.outline-sharp {
        // color: $app-color-blue;
        color: $app-color-gray-29;
        border: 1px solid rgba($color: $app-color-gray-29, $alpha: 1);
        border-radius: 8px;
        @extend %no-bg;
    }
    &.outline-sharp {
        border-radius: 0 !important;
    }
    &.no-outline {
        border: none !important;
    }
    &.no-bg {
        @extend %no-bg;
    }
    &.spinner {
        opacity: 0.5;
        &:before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
            border-radius: 50%;
            border: 2px solid #ffffff;
            border-top-color: #000000;
            animation: spinner 0.8s linear infinite;
        }
    }
    &-plain {
        padding: 8px;
        text-align: center;
        font: normal normal 800 14px/20px Montserrat;
        color: $app-color-blue;
        opacity: 1;
        outline: none;
        cursor: pointer;
        @include draw-border-radius(solid, 1px, $app-color-blue, 5px);
        background-color: $app-color-white;
        &.solid {
            color: #fff;
            background-color: $app-color-blue;
            &.disabled {
                background-color: $app-color-gray-1;
                @include draw-border-radius(solid, 1px, $app-color-gray-1, 5px);
            }
            &.failed {
                background-color: $app-color-red;
                @include draw-border-radius(solid, 1px, $app-color-red, 5px);
            }
        }
        &.outline,
        &.outline-sharp {
            color: $app-color-blue;
            border: 0.5px solid rgba($color: $app-color-blue, $alpha: 1);
            background: transparent !important;
            border-radius: 2.5px;
        }
        &.outline-sharp {
            border-radius: 0 !important;
        }
        &.no-outline {
            border: none !important;
        }
        &.spinner {
            opacity: 0.5;
            &:before {
                content: "";
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                margin-left: -10px;
                border-radius: 50%;
                border: 2px solid #ffffff;
                border-top-color: #000000;
                animation: spinner 0.8s linear infinite;
            }
        }
        &-plain {
            padding: 8px;
            text-align: center;
            font: normal normal 800 14px/20px Montserrat;
            color: $app-color-blue;
            opacity: 1;
            outline: none;
            cursor: pointer;
            @include draw-border-radius(solid, 1px, $app-color-blue, 5px);
            background-color: $app-color-white;
            &.solid {
                background-color: $app-color-blue;
                color: $app-color-white;
                @include draw-border-radius(solid, 1px, $app-color-blue, 5px);
                &.disabled {
                    background-color: $app-color-gray-1;
                    @include draw-border-radius(solid, 1px, $app-color-gray-1, 5px);
                }
                &.failed {
                    background-color: $app-color-red;
                    @include draw-border-radius(solid, 1px, $app-color-red, 5px);
                }
            }
        }
    }
    &.studio-btn {
        background-color: $app-color-blue;
    }
    &-inner-border {
        padding: 5px;
        text-align: center;
        font: normal normal 800 14px/20px Montserrat;
        opacity: 1;
        outline: none;
        cursor: pointer;
        border: none;
        background-color: $app-color-orange;
        color: $app-color-white;
        min-width: 100px;
        & span {
            display: block;
            text-align: center;
            line-height: 22px;
            @include draw-border(solid, 1px, $app-color-white);
        }
        &.disabled {
            background-color: $app-color-gray-1;
            cursor: not-allowed;
        }
    }
    &- {
        &size- {
            & {
                &no-height {
                    height: auto !important;
                    max-height: inherit !important;
                }
            }
            &1 {
                min-height: 30px;
                max-height: 100%;
                min-width: 50px;
                max-width: 150px;
            }
            &2 {
                min-height: 30px;
                max-height: 100%;
                min-width: 100px;
                max-width: 154px;
            }
        }
    }
}